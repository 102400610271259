import React,{useState} from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Home from "./pages/Home/Home";
import University from "./pages/University/University";
import Doctor from "./pages/Doctor/Doctor";
import Profile from "./pages/Profile/Profile";
import Admission from "./pages/Admission/Admission";
import './global.css';
import Navbar from "./components/Navbar/Navbar";


function App() {
  

  return (
    <Router>
      <Navbar />
      <div className="navbar-space-fill"></div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/university" element={<University />} />
        <Route path="/team" element={<Doctor />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/admission-process" element={<Admission />} />
      </Routes>
    </Router>
  );
}
export default App;
