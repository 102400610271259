import React, { useEffect, useState } from 'react';
import './University.css'
import UniversityContent from '../UniversityContent/UniversityContent';
import Footer from '../../components/Footer/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { getContent } from '../../store/actions';

function University() {
  const [university_data, setUniversity_data] = useState([])
  const dispatch = useDispatch();
  var medicoSeniorContent =  useSelector(state=>state.global.content);
  useEffect(() => {
    if (medicoSeniorContent==null){
      dispatch(getContent())
    }
    setUniversity_data([])
    medicoSeniorContent?.data?.medicalUniversityCollection?.items?.forEach(element => {
      setUniversity_data((university_data) => [...university_data,element?.university]);
    });
  },[medicoSeniorContent])
  
  const [university, setUniversity] = useState('Altai State Medical University')
  const [display, setDisplay] = useState('none');
  return (
    <div>
      <div className='university-container'>
        <div className='university-scroll'>
            <h2>Universities</h2>
            <ul>
              {university_data.map((item,index)=>
                <li key={index} onClick={()=>setUniversity(item) } >{item}</li>
              )}
            </ul>
        </div>
        <div className='university-content'>
          <div onClick={() => display==='none' ? setDisplay('flex'):setDisplay('none')} className='browse-universities'>
            <img className='university-ham' src='hamburger-black.png' alt='hamburger' />
            <h3>Browse Universities</h3>
            <div style={{display}} className='university-sidebar'>
              <h2>Universities</h2>
              <ul>
                {university_data.map((item,index)=>
                  <li key={index} onClick={()=>{setUniversity(item);setDisplay('none');} } >{item}</li>
                )}
              </ul>
            </div>
          </div>
          <UniversityContent data = {university_data} setUniversity = {setUniversity} university={university}/>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default University