import React from 'react';
import './UniversityAcademics.css'
import { useSelector } from 'react-redux';

function UniversityAcademics(props) {
  var medicoSeniorContent =  useSelector(state=>state.global.content);
  const data = medicoSeniorContent?.data?.medicalUniversityCollection?.items
  .filter((item) => {
    return item.university === props.university;
  })[0];

  return (
    <div className='university-academics-container'>
      <h2>Duration</h2>
      {data?.duration?.json?.content?.map((dur,index) => (
        <p key={index}>{dur?.content?.[0]?.value}</p>
      ))}
      <h2>Syllabus</h2>
      <div className='university-table'>
        <table>
          {data?.syllabus?.json?.content?.[0]?.content?.map((i,index) => (
            <tr key={index}>
              {i?.content.map((cell,index2) => (
                index===0 ? <th key={index2}>{cell?.content?.[0]?.content?.map((it) => (
                it.value
              ))}</th> : <td key={index2}>{cell?.content?.[0]?.content?.map((it) => (
                it.value
              ))}</td>
              ))}
            </tr>
          ))}
        </table>
      </div>
      <h2>Hostel And Accomodation</h2>
      {data?.hostelAndAccomodation?.json?.content?.map((el,index)=>(
        <p key={index}>{el?.content?.[0]?.value}</p>
      ))}
    </div>
  )
}

export default UniversityAcademics