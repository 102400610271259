import { createSlice } from "@reduxjs/toolkit";
import { 
    getContent } from "./actions";

const initialState = {
    content: null,
    
}

export const globalSlice = createSlice({
    name: "global",
    initialState,
    reducers: {
        updateContent: (state, action) => {
            state.content = action.payload;
        },
        

    },
    extraReducers: (builder) => {
        builder
          
          .addCase(getContent.fulfilled , (state, action)=>{
            try{
                if(action.payload){
                    state.content = action.payload
                    
                    
                }else {
                    state.content = null
                    
                }
            } catch(error) {
                console.info("Error in getting completed leads. Please contact admin")
            }
          })
          .addCase(getContent.rejected, (state)=>{
            state.myleads = null
          })
         

          ;
      },
    })

export const {
    updateContent
} = globalSlice.actions;

export default globalSlice.reducer;