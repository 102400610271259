import React from 'react';
import './DoctorCard.css';
const DoctorCard = ({heading,title,desc,img}) => {
  return (
    <div className='doctor-card'>
        <div className='doctor-card-content'>
          <h2>{heading}</h2>
          <span>
            <h3>{title}</h3>
            <p>{desc}</p>
          </span>
        </div>
        <img src={img} alt='doctor' />
    </div>
  )
}

export default DoctorCard