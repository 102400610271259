import React from 'react';
import './Footer.css'
import { useNavigate } from 'react-router-dom';

function Footer() {

  const navigate = useNavigate();
  const goToHome =()=>{
    navigate('/')
  }
  const goToDoctor =()=>{
    navigate('/team')
  }
  const goToUniversity =()=>{
    dispatch(getContent())
    navigate('/university')
  }
  const goToProfile =()=>{
    navigate('/profile')
  }

  return (
    <footer className="footer">
      <div className='footer-logo' onClick={goToHome} >
        <img className='footer-img' src='medicoboldoncology1.svg' alt='logo' />
        <span className='footer-title'>
          <h2 className='footer-head-text'>Medico</h2>
          <h2 className='footer-head-text'>Senior</h2>
        </span>
      </div>
      <div className='footer-content'>
        <div className='footer-section'>
          <p className="footer-head">Quick Links</p>
          <span onClick={goToUniversity} className='footer-button'>
            <img className='footer-icon' src='home-activated.svg' alt='home' />
            <a className="footer-link">Universities</a>
          </span>
          <span onClick={goToDoctor} className='footer-button'>
            <img className='footer-icon' src='icon_Stethoscope.svg' alt='home' />
            <a className="footer-link">Doctors</a>
          </span>
          <span onClick={goToProfile} className='footer-button' >
            <img className='footer-icon' src='profile-iconinactive.svg' alt='home' />
            <a className="footer-link">Apply Now</a>
          </span>
        </div>
        <div className='footer-section'>
          <p className="footer-head">Get In Touch</p>
          <span className='footer-button'>
            <img className="footer-icon" alt="" src="/location-on.svg" />
            <a href='https://www.google.com/maps/place/713,+Springfield+Rd,+DLF+Phase+5,+Sector+43,+Gurugram,+Haryana+122003/@28.4565545,77.0887914,17z/data=!3m1!4b1!4m5!3m4!1s0x390d18cf530d7c33:0x93a67091a4acb291!8m2!3d28.4565498!4d77.0913663?entry=ttu' 
            className="footer-link" target="_blank" rel="noopener noreferrer" >Location</a>
          </span>
          <span className='footer-button'>
            <img className="footer-icon" alt="" src="/phone.svg" />
            <a href='tel:+919560790176' className="footer-link">Phone</a>
          </span>
          <span className='footer-button'>
            <img className="footer-icon" alt="" src="/email.svg" />
            <a href='mailto: ashwini@medicosenior.com' className="footer-link">Mail</a>
          </span>
        </div>
      </div>
    </footer>
  )
}

export default Footer