import React, { useEffect } from 'react';
import './Home.css';
import Navbar from '../../components/Navbar/Navbar';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import { useDispatch } from 'react-redux';
import { getContent } from '../../store/actions';

const button_style1 = {
  color: 'black',backgroundColor: 'white',boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.25)',
  borderRadius: '0.6rem',fontFamily: 'Montserrat',fontStyle: 'normal',fontWeight: '600',
  fontSize: '1rem',lineHeight: '1.5rem'
};
const button_style2 = {
  color: 'white',backgroundColor: '#1E8364',boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  borderRadius: '0.6rem',fontFamily: 'Montserrat',fontStyle: 'normal',fontWeight: '700',
  fontSize: '1.2rem',lineHeight: '1.4rem',padding: '0.7rem 1.5rem'
};

function Home() {

  const navigate = useNavigate(); 
  const dispatch = useDispatch(); 
  

  useEffect(() => {
    window.scrollTo(0,0)
  },[])  

  const goToUniversity=()=>{
    dispatch(getContent());
    navigate('/university')
  }

  return (
    <div>        
        <div className='home-intro'>
          <div className='home-intro-about'>
            <p>MedicoSenior is a community of Indian doctors who have studied MBBS in Russia and want to help 
                MBBS aspirants to follow their dream of becoming a doctor. </p>
            <Button style={button_style1} onClick={()=>{goToUniversity()}} variant='contained' > Browse University</Button>
          </div>
          <img src='health-checkup--mockup.svg' alt='' />
          {/* <img className='home-back-vector' src='background-svg.svg' alt='background' /> */}
        </div>

        <div className='home-benifits'>
          {/* <img className='home-back-grey-vector' src='background-white.svg' alt='background' /> */}
          <div className='home-benefits-heading'>
            <h1>Benefits of studying in Russia</h1>
            <Button style={button_style2} onClick={()=>{navigate('/team')}} variant='contained'>Visit Our Doctors</Button>
          </div>
          <div className='home-benifits-content-container'>
            <div className='home-benifits-content'>
              <div>
                <h3>Affordable tuition fees</h3> 
                <p>The tuition fees for MBBS in Russia are much lower than in other countries, such as the United States or the United Kingdom.</p> 
              </div>
              <img src='Saving-money.svg' alt='saving-money' />
            </div>
            <div className='home-benifits-content'>
              <div>
                <h3>High-quality education</h3> 
                <p>Russian medical universities are known  for their high-quality education. The curriculum is designed to meet the international standards.</p> 
              </div>
              <img src='Nurse.svg' alt='nurse' />
            </div>
            <div className='home-benifits-content'>
              <div>
                <h3>Easy admission process</h3> 
                <p>The admission process for MBBS in Russia is much easier than in other countries. There is no need to take any entrance exams, and the application process is relatively straightforward.</p> 
              </div>
              <img src='Complete-task.svg' alt='complete-task' />
            </div>
            <div className='home-benifits-content'>
              <div>
                <h3>Safe living conditions</h3> 
                <p>Russia is a safe and comfortable country to live in. The cost of living is relatively low, and there are many opportunities for students to interact with local culture.</p> 
              </div>
              <img src='Stay-at-home.svg' alt='stay-at-home' />
            </div>
          </div>
          <p className='home-benefits-para'>If you are looking for a high-quality, affordable, and easy way to study MBBS, then Russia is a great option. MedicoSenior can help you with every step of the process, from choosing a medical university to getting a visa and finding accommodation.</p>
        </div>

        <div className='home-services'>
          <div className='home-benefits-heading'>
            <h1>We provide</h1>
            <Button style={button_style2} variant='contained' onClick={()=>{goToUniversity() }}>Browse University</Button>
          </div>
          <div className='home-services-content-container'>
            <div className='home-services-content'>
              <img src='customer-support.svg' alt='customer-support' />
              <div>
                <h3>100% Admission Support</h3> 
                <p>From university selection to all the paperwork, we provide you with complete support for your admission.</p> 
              </div>
            </div>
            <div className='home-services-content'>
              <img src='buying-a-house.svg' alt='buying-a-house' />
              <div>
                <h3>Hostels, Canteens and More</h3> 
                <p>Get comfy hostels, Indian canteens, and many other benefits during your stay in Russia.</p> 
              </div>
            </div>
            <div className='home-services-content'>
              <img src='book-fight.svg' alt='book-flight' />
              <div>
                <h3>Visa and Travel Assistance</h3>
                <p>Hassle free Visa procurement and affordable air travels, get the maximum benefits, throughout the course.</p>
              </div>
            </div>
            <div className='home-services-content'>
              <img src='online-class.svg' alt='online-class' />
              <div>
                <h3>FMGE/NExT Coaching</h3>
                <p>Attend FMGE/NExT Coaching during your study, within the university and get the best career guidance from the experts.</p>
              </div>
            </div>
          </div>
        </div>

        <Footer/>
    </div>
  )
};

export default Home;