import React from 'react';
import './UniversityAbout.css'
import { useSelector } from 'react-redux';

function UniversityAbout(props) {
  var medicoSeniorContent =  useSelector(state=>state.global.content);
  const data = medicoSeniorContent?.data?.medicalUniversityCollection?.items
  .filter((item) => {
    return item.university === props.university;
  })?.[0];
  return (
    <div className='university-about-container'>
      <h2>Quick Highlight</h2>
      <div className='university-table'>
        <table>
          {data?.quickHighlight?.json?.content?.[0]?.content?.map((i,index) => (
            <tr key={index}>
              <th>{i?.content?.[0]?.content?.[0]?.content?.[0]?.value}</th>
              <td>{i?.content?.[1]?.content?.[0]?.content?.[0]?.value}</td>
            </tr>
          ))}
        </table>
      </div>
      <h2>About Campus</h2>
      <p>{data?.about}</p>
      <h2>Ranking</h2>
      <div className='university-table'>
        <table>
          {data?.ranking?.json?.content?.[0]?.content?.map((i,index) => (
            <tr key={index}>
              <th>{i?.content?.[0]?.content?.[0]?.content?.[0]?.value}</th>
              <td>{i?.content?.[1]?.content?.[0]?.content?.[0]?.value}</td>
            </tr>
          ))}
        </table> 
      </div>
      <h2>Affiliation</h2>
      <ol>
        {data?.affiliation?.json?.content?.[0]?.content?.map((i,index) => (
          <li key={index}>{i?.content?.[0]?.content?.[0]?.value}</li>
        ))}
      </ol>
      <h2>Benefits</h2>
      <ol>
        {data?.benifits?.json?.content?.[0]?.content?.map((i,index) => (
          <li key={index}>{i?.content?.[0]?.content?.[0]?.value}</li>
        ))}
      </ol>
      <h2>Advantages</h2>
      <ol>
        {data?.advantages?.json?.content?.[0]?.content?.map((i,index) => (
          <li key={index}>{i?.content?.[0]?.content?.[0]?.value}</li>
        ))}
      </ol>
    </div>
  )
}

export default UniversityAbout