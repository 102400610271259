import React, { useEffect } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';


function Admission() {

  useEffect(() => {
    window.scrollTo(0,0)
  },[])  

  return (
    <div>
        <Navbar/>

        <h1>Admission Process</h1>
        
        <h3>Check the eligibility criteria</h3>
        <p>
        The minimum eligibility criteria for admission to Smolensk State Medical Academy for Indian students are:
        Age: At least 17 years old by December 31st of the year of admission.
        Education: 10+2 or an equivalent examination with a minimum of 50% in Physics, Chemistry, Biology, and English individually.
        NEET: For Indian applicants, qualifying the NEET is mandatory.
        </p>

        <h3>Apply for Admission</h3>
        <p>
        You can apply for admission to Smolensk State Medical Academy online or through a representative agency. The application process typically involves submitting the following documents:
        Application form
        10+2 or equivalent examination certificate
        NEET scorecard
        Passport or Adhaar card
        Medical certificate
        Letter of motivation
        </p>

        <h3>Get a Visa</h3>
        <p>
        Once you have been accepted to Smolensk State Medical Academy, you will need to apply for a student visa. The visa process typically takes several weeks.
        </p>

        <h3>Arrange for Accommodation</h3>
        <p>
        Smolensk State Medical Academy provides accommodation for students. However, you may also choose to arrange for your own accommodation.
        </p>

        <h3>Arrive in Russia and register for classes</h3>
        <p>
        Once you have arrived in Russia, you will need to register for classes at Smolensk State Medical Academy. The registration process typically takes several days.
        </p>

    <Footer/>

    </div>
  )
}

export default Admission