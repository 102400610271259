import React,{useState} from 'react';
import './Navbar.css';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getContent } from '../../store/actions';
const button_css = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '20px',
  color: '#FFFFFF',
};
function Navbar() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const goToHome =()=>{
    navigate('/')
  }

  const goToDoctor =()=>{
    navigate('/team')
  }

  const goToUniversity =()=>{
    dispatch(getContent())
    navigate('/university')
  }
  
  const goToProfile =()=>{
    navigate('/profile')
  }
  
  const goToAdmission =()=>{
    navigate('/admission-process')
  }
  const [display, setDisplay] = useState('none');
  return (
    <div className='navbar-main'>
      <div className='navbar-container'>
        <div onClick={goToHome} className='navbar-logo'>
          <img className='navbar-img' src='medicoboldoncology1.svg' alt='logo' />
          <span className='navbar-title'>
            <h2 className='navbar-head-text'>Medico</h2>
            <h2 className='navbar-head-text'>Senior</h2>
          </span>
        </div>

        <div className='navbar-buttons'>
          <span className='navbar-button'>
            <img className='navbar-icons' src='home-activated.svg' alt='home' />
            <Button style={button_css} variant="text" onClick={()=>goToHome() } >Home</Button>
          </span>
          <span className='navbar-button'>
            <img className='navbar-icons' src='education.svg' alt='university' />
            <Button style={button_css} variant="text" onClick={()=>goToUniversity() }>University</Button>
          </span>
          <span className='navbar-button'>
            <img className='navbar-icons' src='icon_Stethoscope.svg' alt='home' />
            <Button style={button_css} variant="text" onClick={()=>goToDoctor() }>Doctors</Button>
          </span>
          {/* <span className='navbar-button'>
            <img className='navbar-icons' src='home-iconactivated.svg' alt='home' />
            <Button style={button_css} variant="text" onClick={()=>goToAdmission() }>Admission</Button>
          </span> */}
          <span className='navbar-button'>
            <img className='navbar-icons' src='profile-iconinactive.svg' alt='home' />
            <Button style={button_css} variant="text" onClick={()=>goToProfile() }>Apply Now</Button>
          </span>
        </div>
        <img onClick={() => display==='none' ? setDisplay('flex'):setDisplay('none')} className='navbar-ham' src='icons8-menu-150.png' alt='hamburger' />
      </div>
      <div style={{display}} className='navbar-ham-buttons'>
        <span className='navbar-button'>
          <img className='navbar-icons' src='home-activated.svg' alt='home' />
          <Button style={button_css} variant="text" onClick={()=>{goToHome();setDisplay('none');} } >Home</Button>
        </span>
        <span className='navbar-button'>
          <img className='navbar-icons' src='education.svg' alt='university' />
          <Button style={button_css} variant="text" onClick={()=>{goToUniversity();setDisplay('none');} }>University</Button>
        </span>
        <span className='navbar-button'>
          <img className='navbar-icons' src='icon_Stethoscope.svg' alt='home' />
          <Button style={button_css} variant="text" onClick={()=>{goToDoctor();setDisplay('none');} }>Doctors</Button>
        </span>
        {/* <span className='navbar-button'>
          <img className='navbar-icons' src='home-iconactivated.svg' alt='home' />
          <Button style={button_css} variant="text" onClick={()=>goToAdmission() }>Admission</Button>
        </span> */}
        <span className='navbar-button'>
          <img className='navbar-icons' src='profile-iconinactive.svg' alt='home' />
          <Button style={button_css} variant="text" onClick={()=>{goToProfile();setDisplay('none');} }>Apply Now</Button>
        </span>
      </div>
    </div>
  )
}

export default Navbar