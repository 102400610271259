import React, { useEffect } from 'react';
import './Doctor.css';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import DoctorCard from '../../components/DoctorCard/DoctorCard';
import LeadershipCard from '../../components/DoctorCard/LeadershipCard';

function Doctor() {

    
  useEffect(() => {
    window.scrollTo(0,0)
  },[])   

  return (
    <div>
      <div className='doctor-heading'>
        {/* <img className='doctor-back' src='background-svg.svg' alt='background' /> */}
        <h1>Our Doctor's Team</h1>
      </div>
      
      <div className='doctor-content'>
        <DoctorCard heading="Dr. Anand Jha" title="Orenberg State Medical University" desc="Working at Dr. Baba Saheb Ambedkar Medical College and Hospital (BSAMCH)" img="Doctor2-photo.png" />
        <DoctorCard heading="Dr Yuvraj Malhotra" title="Kazakh National Medical University" desc="Safdarjung Hospital" img="Doctor3-photo.png" />
        <DoctorCard heading="Dr. Swati Chauhan" title="Tianjin Medical University" desc="Working at Hindu Rao Hospital, Delhi" img="Doctor1-photo.png" />
      </div>
      {/* <img className='doctor-back-art' src='BG-Design.svg' alt='background-art' /> */}

      <h1>Leadership Team</h1>
      <div className='doctor-content'>
      <LeadershipCard 
        heading="Mr. Ashwini Chauhan" 
        title="Indian Institute Of Technology, Kanpur" 
        phone="Phone: 8447804765"
        email="Email: ashwini.11.chauhan@gmail.com"
        address="Address:Plot No. 8A/1, Niranjan Park, BLOCK C, New Delhi 110043" 
        img="ashwini-founder.jpeg" />
      </div>

      <Footer />



    </div>
  )
}

export default Doctor