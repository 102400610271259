import React, { useState } from 'react';
import './UniversityAdmission.css'
import { useSelector } from 'react-redux';



function UniversityAdmission(props) {

  var medicoSeniorContent =  useSelector(state=>state.global.content);
  const data = medicoSeniorContent?.data?.medicalUniversityCollection?.items
  .filter((item) => {
    return item.university === props.university;
  })[0];
    
    // const [page, setPage] = useState(null);
    // const [universityPage, setUniversityPage] = useState(null)

    // var medicoSeniorContent =  useSelector(state=>state.global.content);
    
    // console.log(medicoSeniorContent)
    
    

    // if (!page) {
    //     return "Loading...";
    //   }

  return (
    <div className='university-admission-container'>
      <h2>Eligibility</h2>
      <ul>
        {data?.eligibility?.json?.content?.[0]?.content?.map((i,index) => (
          <li key={index}>{i?.content?.[0]?.content?.[0]?.value}</li>
        ))}
      </ul>
      <h2>Admission Process</h2>
      <ul>
        {data?.admission?.json?.content?.[0]?.content?.map((i,index) => (
          <li key={index}><b>{i?.content?.[0]?.content?.[0]?.value}</b>{" " + i?.content?.[0]?.content?.[1]?.value}</li>
        ))}
      </ul>
      <h2>Documents Required</h2>
      <ul>
        {data?.documents?.json?.content?.[0]?.content?.map((i,index) => (
          <li key={index}>{i?.content?.[0]?.content?.[0]?.value}</li>
        ))}
      </ul>
    </div>
  )
}

export default UniversityAdmission