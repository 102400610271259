import {  createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getContent = createAsyncThunk(
    "global/getContent", async () => {

    const query = `
    {
        
            medicalUniversityCollection{
              items{
                university,
                address,
                airport,
                mainFee,
                city,
                about,
                affiliation{
                  json
                },
                benifits{
                  json
                },
                quickHighlight{
                  json
                },
                advantages{
                  json
                },
                duration{
                  json
                },
                facilities{
                  json
                },
                fees{
                  json
                },
                ranking{
                  json
                },
                eligibility{
                 json 
                },
                admission{
                  json
                },
                documents{
                  json
                },
                admission{
                  json
                },
                syllabus{
                  json
                },
                hostelAndAccomodation{
                  json
                },
                livingCost{
                  json
                }
                
                
                
              }
            
          }
    }
    `


    try {

        
    
     const body = { query };        
     
     const config = {
				headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer AwIAg2_UPH1uaQ5cqSTfGmNZ_v_2n-zP0IaJE-Vw0Vo",
				}
			};

      const url = `https://graphql.contentful.com/content/v1/spaces/tolxqtb3fq9f/`;
      
      const response = await axios.post(url,body,config);
      
      const data  =  await response.data;
      return  data ;
    } catch (error) {
      console.error("Unable to get the data from contentful", error);
    }

    
  });