import React, { useState } from 'react';
import './UniversityContent.css';
import UniversityAbout from '../../components/UniversityAbout/UniversityAbout';
import UniversityAcademics from '../../components/UniversityAcademics/UniversityAcademics';
import UniversityAdmission from '../../components/UniversityAdmission/UniversityAdmission';
import UniversityFees from '../../components/UniversityFees/UniversityFees';


function UniversityContent(props) {
  
  

  const [window, setWindow] = useState('about');

  return (
    <div className='university-content-container'>
      <div className='university-heading'>
        <h1>{props.university}</h1>        
      </div>
      <div className='university-button-container'>
        <h3 onClick={()=>{setWindow('about')}} >About Campus </h3>
        <h3 onClick={()=>{setWindow('academic')}} >Academic Life</h3>
        <h3 onClick={()=>{setWindow('admission')}} >Admission Process </h3>
        <h3 onClick={()=>{setWindow('fees')}} >Fees & Living Cost</h3>
      </div>
      <div className='university-content-main'>
        {(window=='about'&&<UniversityAbout university={props.university}/> )}    
        {(window=='academic'&&<UniversityAcademics university={props.university}/> )}    
        {(window=='admission'&&<UniversityAdmission  university={props.university}/> )}    
        {(window=='fees'&&<UniversityFees university={props.university}/> )}    
      </div>
    </div>
  )
}

export default UniversityContent