import React from 'react';
import './UniversityFees.css'
import { useSelector } from 'react-redux';

function UniversityFees(props) {
  var medicoSeniorContent =  useSelector(state=>state.global.content);
  const data = medicoSeniorContent?.data?.medicalUniversityCollection?.items
  .filter((item) => {
    return item.university === props.university;
  })[0];

  return (
    <div className='university-fees-container'>
      <h2>Fees</h2>
      <div className='university-table'>
        <table>
          {data?.fees?.json?.content?.[0]?.content?.map((i,index) => (
            <tr key={index}>
              {i?.content.map((cell,index2) => (
                index===0 ? <th key={index2}>{cell?.content?.[0]?.content?.[0]?.value}</th> : <td key={index2}>{cell?.content?.[0]?.content?.[0]?.value}</td>
              ))}
            </tr>
          ))}
        </table>
      </div>
      <h2>Living Cost</h2>
      <div className='university-table'>
        <table>
          {data?.livingCost?.json?.content?.[0]?.content?.map((i,index) => (
            <tr key={index}>
              {i?.content.map((cell,index2) => (
                index===0 ? <th key={index2}>{cell?.content?.[0]?.content?.[0]?.value}</th> : <td key={index2}>{cell?.content?.[0]?.content?.[0]?.value}</td>
              ))}
            </tr>
          ))}
        </table>
      </div>
    </div>
  )
}

export default UniversityFees