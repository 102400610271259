import React, { useState } from 'react';
import './Profile.css';
import Footer from '../../components/Footer/Footer';

function Profile() {

  const [submit,setSubmit] = useState(false)

  
  function Submit(event) {
    const formEle = document.querySelector("form");
    event.preventDefault()
    const formData = new FormData(formEle);
    setSubmit(true)
    console.log(formData)
    fetch(
      "https://script.google.com/macros/s/AKfycbwgPOJ3jnV5-UcP9XiEc1h3O860U4temYGhDZuOGJZPE_qSbWyeM2PzixA6SqV36X7T0Q/exec",
      {
        method: "POST",
        body:formData
        
      }
    )
      .then((res) => res.json())
      .then((data) => {
        
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }


  return (
    <div className='profile-container'>
        {(submit)?
        <div className='profile-submitted'>
          <h2>Thanks for submitting your response. One of the Medico Senior will contact you soon </h2>
        </div>
          :
        <div className='profile'>
          <form className="form" onSubmit={(e) => Submit(e)}>
            <input placeholder="Name" name="Name" type="text" required />
            <input placeholder="Mobile" name="Mobile" type="text" required/>
            <input placeholder="Email" name="Email" type="text" required/>
            <input placeholder="Qualification" name="Qualification" type="text" />
            <input placeholder="Address" name="Address" type="text" />
            <input placeholder="City" name="City" type="text" />
            <input placeholder="State" name="State" type="text" />
            <input placeholder="Country" name="Country" type="text" />
            <input placeholder="Message" name="Message" type="text" />
            <input placeholder="Course" name="Course" type="text" />
          </form>
          <button onClick={Submit} className='submit-button' type="submit">Submit</button>
        </div>}
        <Footer />
    </div>
  )
}

export default Profile